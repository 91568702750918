import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Code`}</h1>
    <p><a parentName="p" {...{
        "href": "https://github.com/materia-dex/materia-contracts-core/blob/master/contracts/MateriaFactory.sol"
      }}><inlineCode parentName="a">{`MateriaFactory.sol`}</inlineCode></a></p>
    <h1 {...{
      "id": "address",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#address",
        "aria-label": "address permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Address`}</h1>
    <p><inlineCode parentName="p">{`MateriaFactory`}</inlineCode>{` is deployed at `}<inlineCode parentName="p">{`0xb498a69ff7b9a73c58491d564fc6a462b259c860`}</inlineCode>{` on the Ethereum `}<a parentName="p" {...{
        "href": "https://etherscan.io/address/0xb498a69ff7b9a73c58491d564fc6a462b259c860"
      }}>{`mainnet`}</a>{` and the `}<a parentName="p" {...{
        "href": "https://ropsten.etherscan.io/address/0xb498a69ff7b9a73c58491d564fc6a462b259c860"
      }}>{`Ropsten`}</a>{` testnet.
It was built from commit tagged as `}<inlineCode parentName="p">{`depoloy`}</inlineCode>{`.`}</p>
    <h1 {...{
      "id": "events",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#events",
        "aria-label": "events permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Events`}</h1>
    <h2 {...{
      "id": "paircreated",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paircreated",
        "aria-label": "paircreated permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PairCreated`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`event PairCreated(address indexed token0, address indexed token1, address pair, uint);
`}</code></pre>
    <p>{`Emitted each time a pair is created via `}<a parentName="p" {...{
        "href": "#createpair"
      }}>{`createPair`}</a>{`.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`token0`}</inlineCode>{` is guaranteed to be strictly less than `}<inlineCode parentName="li">{`token1`}</inlineCode>{` by sort order.`}</li>
      <li parentName="ul">{`The final `}<inlineCode parentName="li">{`uint`}</inlineCode>{` log value will be `}<inlineCode parentName="li">{`1`}</inlineCode>{` for the first pair created, `}<inlineCode parentName="li">{`2`}</inlineCode>{` for the second, etc. (see `}<a parentName="li" {...{
          "href": "#allpairs"
        }}>{`allPairs`}</a>{`/`}<a parentName="li" {...{
          "href": "#getpair"
        }}>{`getPair`}</a>{`).`}</li>
    </ul>
    <h1 {...{
      "id": "read-only-functions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#read-only-functions",
        "aria-label": "read only functions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Read-Only Functions`}</h1>
    <h2 {...{
      "id": "getpair",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#getpair",
        "aria-label": "getpair permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`getPair`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function getPair(address tokenA, address tokenB) external view returns (address pair);
`}</code></pre>
    <p>{`Returns the address of the pair for `}<inlineCode parentName="p">{`tokenA`}</inlineCode>{` and `}<inlineCode parentName="p">{`tokenB`}</inlineCode>{`, if it has been created, else `}<inlineCode parentName="p">{`address(0)`}</inlineCode>{` (`}<inlineCode parentName="p">{`0x0000000000000000000000000000000000000000`}</inlineCode>{`).`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`tokenA`}</inlineCode>{` and `}<inlineCode parentName="li">{`tokenB`}</inlineCode>{` are interchangeable.`}</li>
      <li parentName="ul">{`Pair addresses can also be calculated deterministically, see `}<Link to='/docs/materia/javascript-SDK/getting-pair-addresses/' mdxType="Link">{`Pair Addresses`}</Link>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "allpairs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#allpairs",
        "aria-label": "allpairs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`allPairs`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function allPairs(uint) external view returns (address pair);
`}</code></pre>
    <p>{`Returns the address of the `}<inlineCode parentName="p">{`n`}</inlineCode>{`th pair (`}<inlineCode parentName="p">{`0`}</inlineCode>{`-indexed) created through the factory, or `}<inlineCode parentName="p">{`address(0)`}</inlineCode>{` (`}<inlineCode parentName="p">{`0x0000000000000000000000000000000000000000`}</inlineCode>{`) if not enough pairs have been created yet.`}</p>
    <ul>
      <li parentName="ul">{`Pass `}<inlineCode parentName="li">{`0`}</inlineCode>{` for the address of the first pair created, `}<inlineCode parentName="li">{`1`}</inlineCode>{` for the second, etc.`}</li>
    </ul>
    <h2 {...{
      "id": "allpairslength",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#allpairslength",
        "aria-label": "allpairslength permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`allPairsLength`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function allPairsLength() external view returns (uint);
`}</code></pre>
    <p>{`Returns the total number of pairs created through the factory so far.`}</p>
    <h2 {...{
      "id": "feeto",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#feeto",
        "aria-label": "feeto permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`feeTo`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function feeTo() external view returns (address);
`}</code></pre>
    <p>{`Returns the receiver of the Materia fee, if disabled it returns the zero address.
See `}<Link to='/docs/materia/advanced-topics/fees/#protocol-charge-calculation' mdxType="Link">{`Protocol Charge Calculation`}</Link>{`.`}</p>
    <h2 {...{
      "id": "defaultmateriafee",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#defaultmateriafee",
        "aria-label": "defaultmateriafee permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`defaultMateriaFee`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function defaultMateriaFee() external view returns (uint);
`}</code></pre>
    <p>{`Returns the default Materia fee i.e. the initial Materia Fee for new pairs.`}</p>
    <h2 {...{
      "id": "defaultswapfee",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#defaultswapfee",
        "aria-label": "defaultswapfee permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`defaultSwapFee`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function defaultSwapFee() external view returns (uint);
`}</code></pre>
    <p>{`Returns the default swap fee i.e. the initial swap Fee for new pairs.`}</p>
    <h2 {...{
      "id": "owner",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#owner",
        "aria-label": "owner permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`owner`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function owner() external view returns (address);
`}</code></pre>
    <p>{`Returns the owner, currently this is the orchestrator.`}</p>
    <h1 {...{
      "id": "state-changing-functions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#state-changing-functions",
        "aria-label": "state changing functions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`State-Changing Functions`}</h1>
    <h2 {...{
      "id": "createpair",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#createpair",
        "aria-label": "createpair permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`createPair`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function createPair(address tokenA, address tokenB) external returns (address pair);
`}</code></pre>
    <p>{`Creates a pair for `}<inlineCode parentName="p">{`tokenA`}</inlineCode>{` and `}<inlineCode parentName="p">{`tokenB`}</inlineCode>{` if one doesn’t exist already.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`tokenA`}</inlineCode>{` and `}<inlineCode parentName="li">{`tokenB`}</inlineCode>{` are interchangeable.`}</li>
      <li parentName="ul">{`Emits `}<a parentName="li" {...{
          "href": "#paircreated"
        }}>{`PairCreated`}</a>{`.`}</li>
    </ul>
    <p>{`Can be called only by the owner.`}</p>
    <p>{`##setDefaultMateriaFee`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function setDefaultMateriaFee(uint256 _defaultMateriaFee) external;s
`}</code></pre>
    <p>{`Set the deafult new Materia fee for new pairs, must be called by the owner.`}</p>
    <p>{`##setDefaultSwapFee`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function setDefaultMateriaFee(uint256 _defaultMateriaFee) external;s
`}</code></pre>
    <p>{`Set the deafult new swap fee for new pairs, must be called by the owner.`}</p>
    <p>{`##setFees`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function setFees(address pair, uint256 materiaFee, uint256 swapFee) external;
`}</code></pre>
    <p>{`Set the fees for a specific pair, must be called by the owner.`}</p>
    <h1 {...{
      "id": "interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#interface",
        "aria-label": "interface permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Interface`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`import '@materia/materia-constracts-core/contracts/interfaces/IMateriaFactory.sol';
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`interface IMateriaFactory {
    event PairCreated(address indexed token0, address indexed token1, address pair, uint256);

    function feeTo() external view returns (address);

    function getPair(address tokenA, address tokenB) external view returns (address pair);

    function allPairs(uint256) external view returns (address pair);

    function allPairsLength() external view returns (uint256);

    function createPair(address tokenA, address tokenB) external returns (address pair);

    function setFeeTo(address) external;

    function setDefaultMateriaFee(uint256) external;

    function setDefaultSwapFee(uint256) external;

    function transferOwnership(address newOwner) external;

    function setFees(
        address,
        uint256,
        uint256
    ) external;

    function owner() external view returns (address);
}
`}</code></pre>
    <h1 {...{
      "id": "abi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#abi",
        "aria-label": "abi permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ABI`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import IMateriaFactory from '@materia/materia-contracts-core/build/IMateriaFactory.json'
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      